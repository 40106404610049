<template>
  <v-container>
    <v-btn text @click="$router.go(-1)" ><v-icon
        left
        dark
      >
        mdi-backburger
    </v-icon> Zurück zu Referenzen</v-btn>
    <ImageGallery v-model="galleryData"/>
  </v-container>
</template>
<script>
import ImageGallery from '@/components/ImageGallery.vue';

export default {
  name: 'fassade-mainz-kostheim',
  components: {
    ImageGallery,
  },
  data: () => ({
    galleryData: {
      imagePath: 'https://medo-sanierung.de/public_files/img/references/fassade-mainz-kostheim',
      imageCount: 20,
      imageFileFormat: '.jpg',
      name: 'Neue Fassade an einem Mehrfamilienhaus in Mainz-Kostheim',
      description:
          'Neue Fassade an einem Mehrfamilienhaus in Mainz-Kostheim. Hier wurde der alte Außenputz an einem Mehrfamilienhaus, sowie der Fliesenbelag an der Treppe zum Keller abgeschlagen. Außer die Strassenseite, wurden alle Seiten des Haupthauses, sowie das komplete Nebengebäude im Hof isoliert und gedämmt. Nachdem der neue Putz auf die Fassade kam, folgten die Maler- und Lackierarbeiten. Der Treppenabgang zum Keller wurde saniert und mit rutschfesten und geeigneten Fliesen verkleidet.',
    },
    //
  }),
};
</script>
